import React, { useEffect, useRef, useState } from 'react'
import Modal from '../ui/Modal'
import { sendSalida } from '../../services/SelfManagement/sendSalida.service'
import moment from 'moment'
import Icon from '@mdi/react'
import { mdiCheck } from '@mdi/js'

export const ModalExit = ({ modalData, handleClose, handleEndOperation }) => {
  const inputDni = useRef(null)
  const [dni, setDni] = useState('')
  const [success, setSuccess] = useState(false)


  useEffect(() => {
    if (!inputDni) return

    inputDni?.current?.focus()
  }, [inputDni, modalData])


  const handleInputChange = (e) => {
    setDni(e.target.value)
  }


  const handleSumbit = async (e) => {
    e.preventDefault();

    await sendSalida(modalData?.data?.nota_venta, { datos_dni: dni })
      .then(res => {
        setSuccess(true)
        setDni('')
        return res
      })
      .catch(err => console.error(err))
  }

  const handleCloseModal = (e) => {
    e.preventDefault()

    handleClose(e)
  }

  const handleEndOpModal = (e) => {
    e.preventDefault()
    handleEndOperation()
  }

  return (
    <Modal state={modalData.display} close={handleClose}>
      <div className='self-management-modal-exit-container'>
        <button className='btn-close' onClick={handleCloseModal}>X</button>

        <div className='content'>
          <h3 className='title'>SALIDA</h3>

          <span className='nv'>Nota de venta: <b>{modalData?.data?.nota_venta}</b></span>

          {modalData?.data?.fecha_salida

            ?
            <div className='exit-data'>
              <p>DNI: <b>{modalData?.data?.datos_dni ?? 'Error'}</b></p>
              <p>Fecha salida: <b>{modalData?.data?.fecha_salida ? moment(modalData?.data?.fecha_salida).format('DD-MM-YYYY') : 'Error'}</b></p>
            </div>

            : !success
              ?
              <form className='exit-form' onSubmit={handleSumbit}>
                <label className='code-input'>
                  Datos DNI:

                  <input autoFocus ref={inputDni} value={dni} onChange={handleInputChange} />
                </label>

                <button className='btn-submit' type='submit' disabled={!dni}>Enviar</button>
              </form>

              : <div className='exit-success'>
                <h3>Salida registrada con éxito</h3>

                <figure className='exit-check-success'>
                  <Icon path={mdiCheck} />
                </figure>

                <button className='btn-submit' onClick={handleEndOpModal}>Cerrar</button>
              </div>
          }
        </div>
      </div>
    </Modal >
  )
}
