import { stringCapitalize } from '../helpers/format'

export const types = {
   SET_WEBPAY_RESPONSE: '[PAYMENTS] set webpay payment status data',
   SET_MERCADO_PAGO_RESPONSE: '[PAYMENTS] set mercado pago payment status data',
   SET_LOADING: '[PAYMENTS] set loading state',
   SET_ORDER: '[PAYMENTS] set order data',
   SET_SCHEDULE: '[PAYMENTS] set schedule data',
   SET_SCHEDULE_STATUS: '[PAYMENTS] set scheduled status',

   SET_BANK_ACCOUNTS: '[PAYMENTS] set bank accounts data',
}



const initialState = {
   orderData: null,
   success: null,
   error: null,
   loading: true,
   schedule: [],
   placeHasSchedule: null,
   isScheduled: null,
   isPartialPayment: null,
   payment: null,

   loadingPayment: false,

   bankAccounts: [],
   loadingBankAccounts: true,
}



export const paymentsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_WEBPAY_RESPONSE:
      case types.SET_MERCADO_PAGO_RESPONSE:
         return {
            ...state,
            orderData: action.payload.orderData,
            success: action.payload.success,
            error: action.payload.error,
            isPartialPayment: action.payload.isPartialPayment,
            payment: action.payload.payment,
         }

      case types.SET_ORDER:
         return {
            ...state,
            orderData: action.payload
         }

      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_SCHEDULE:
         return {
            ...state,
            schedule: action.payload.schedule,
            placeHasSchedule: action.payload.placeHasSchedule,
         }

      case types.SET_SCHEDULE_STATUS:
         return {
            ...state,
            isScheduled: action.payload
         }

      case types.SET_BANK_ACCOUNTS:
         return {
            ...state,
            bankAccounts: action.payload
         }

      default:
         return state;
   }
}